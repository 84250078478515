import React from "react"
import InvestorRelationsLayout from '../../../components/InvestorRelationsLayout'
import { graphql } from 'gatsby'
import LocaleContext from '../../../contexts/LocaleContext'
import Layout from '../../../components/Layout'
import OurProfile from "../../../components/OurProfile"
import Menu from '../../../components/Menu'
import Footer from '../../../components/Footer'

export const query = graphql`
  query ($locale: String!){
    datoCmsInvestorRelationsPage ( locale: { eq: $locale }){
      board
      boardMembers{
        name
        introductionNode {
          childMarkdownRemark {
            html
          }
        }
        id
        title
        photo {
          fixed(width: 300, height: 300, imgixParams: {crop: "faces", ar: "1", fit: "crop"}) {
            ...GatsbyDatoCmsFixed
          }
        }
        regulatoryInformation
      }
    }
    datoCmsNewMenu ( locale: { eq: $locale }){
      ...Menu
    }
    datoCmsFooter ( locale: { eq: $locale }) {
      ...Footer
    }
  }
  `

export default function Board({ data, pageContext }) {
  const { board, boardMembers } = data.datoCmsInvestorRelationsPage
  return (

    <LocaleContext.Provider value={pageContext.locale || 'en'}>
      <Layout transparentMenu locale={pageContext.locale}>
        <Menu
            transparent={true}
            menu={data.datoCmsNewMenu}
            socialMedia={data.datoCmsFooter.socialMedia}
          />
        <InvestorRelationsLayout>
          <React.Fragment>
            <div className="margin-top" dangerouslySetInnerHTML={{
              __html: board,
            }}></div>
            <OurProfile
              members={boardMembers}
              gridSize={'small'}
              regulatory={true}
            />
          </React.Fragment>
        </InvestorRelationsLayout>
        <Footer footer={data.datoCmsFooter} />
      </Layout>
    </LocaleContext.Provider>
  )
}
